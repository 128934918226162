
































































import { Component, Vue } from 'vue-property-decorator';

export class ContactFormModel {
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  message = '';
}

@Component<ContactFormComponent>({})
export default class ContactFormComponent extends Vue {
  private model = new ContactFormModel();

  get isValid(): boolean {
    return (
      this.model.firstName !== '' &&
      this.model.lastName !== '' &&
      this.model.email !== '' &&
      this.model.phone !== '' &&
      this.model.message !== ''
    );
  }

  submit(): void {
    this.$emit('submit', this.model);
  }
}
