var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "bg-fixed bg-no-repeat bg-cover landing-page-wrapper",
      attrs: { id: _vm.sections.HOME }
    },
    [
      _c(
        "div",
        { staticClass: "justify-center text-white sm:flex bg-heropage" },
        _vm._l(_vm.menuItems, function(item, index) {
          return _c(
            "button",
            {
              key: index,
              staticClass:
                "block px-10 py-6 uppercase cursor-pointer font-extralight focus:outline-none",
              on: {
                click: function($event) {
                  return _vm.scrollToSection(item)
                }
              }
            },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      ),
      _c("section", { staticClass: "section-container" }, [
        _c("div", { staticClass: "section-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "flex justify-center mt-48" }, [
            _c(
              "a",
              {
                staticClass:
                  "flex items-center justify-center w-12 h-12 mr-2 text-2xl font-semibold bg-white rounded-full",
                attrs: { href: _vm.linkedInUrl, target: "_blank" }
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fab", "linkedin-in"] }
                })
              ],
              1
            ),
            _c(
              "a",
              {
                staticClass:
                  "flex items-center justify-center w-12 h-12 ml-2 text-2xl font-semibold bg-white rounded-full",
                attrs: { href: _vm.facebookUrl, target: "_blank" }
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fab", "facebook-f"] }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "section",
        {
          staticClass: "section-container landing-page-product-summary",
          attrs: { id: _vm.sections.PRODUCT_SUMMARY }
        },
        [_vm._m(1)]
      ),
      _c(
        "section",
        {
          staticClass: "text-white bg-black section-container",
          attrs: { id: _vm.sections.VISION }
        },
        [
          _c("div", { staticClass: "section-content" }, [
            _c("div", { staticClass: "pb-16 text-2xl text-center" }, [
              _vm._v("THE VISION")
            ]),
            _vm._m(2),
            _c("div", { staticClass: "flex justify-center my-24" }, [
              _c("iframe", {
                attrs: {
                  width: "979",
                  height: "474",
                  src: _vm.youtubeVideoUrl,
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }
              })
            ])
          ])
        ]
      ),
      _c(
        "section",
        {
          staticClass: "section-container landing-page-end-user",
          attrs: { id: _vm.sections.END_USER }
        },
        [_vm._m(3)]
      ),
      _c(
        "section",
        {
          staticClass: "section-container landing-page-connect",
          attrs: { id: _vm.sections.CONNECT }
        },
        [_vm._m(4)]
      ),
      _c("div", { staticClass: "p-4 text-center text-white bg-heropage" }, [
        _c("div", { staticClass: "flex justify-center mb-4 text-black" }, [
          _c(
            "a",
            {
              staticClass:
                "flex items-center justify-center w-12 h-12 mr-2 text-2xl font-semibold bg-white rounded-full",
              attrs: { href: _vm.linkedInUrl, target: "_blank" }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fab", "linkedin-in"] }
              })
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass:
                "flex items-center justify-center w-12 h-12 ml-2 text-2xl font-semibold bg-white rounded-full",
              attrs: { href: _vm.facebookUrl, target: "_blank" }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fab", "facebook-f"] }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "text-sm" }, [
          _vm._v(_vm._s(_vm.companyAddress))
        ]),
        _c("div", { staticClass: "text-sm" }, [
          _c("a", { attrs: { href: "mailto:" + _vm.companyEmail } }, [
            _vm._v(_vm._s(_vm.companyEmail))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-col justify-center px-24 sm:justify-start" },
      [
        _c("img", {
          staticClass: "mt-24",
          attrs: {
            width: "350",
            src: require("@/assets/heropage/skyhub-logo.png"),
            alt: "SkyHub Logo"
          }
        }),
        _c(
          "div",
          { staticClass: "text-center text-white sm:text-left sm:ml-12" },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-3xl tracking-widest sm:text-6xl font-extralight"
              },
              [
                _c("span", { staticClass: "text-blue-300 text-shadow" }, [
                  _vm._v("SKY")
                ]),
                _vm._v("HUB ")
              ]
            ),
            _c("div", { staticClass: "text-xl" }, [
              _vm._v(" Orchestration at the "),
              _c("span", { staticClass: "text-blue-300 text-shadow" }, [
                _vm._v("Edge...")
              ])
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-content" }, [
      _c("div", { staticClass: "text-2xl text-center text-white" }, [
        _vm._v("PRODUCT SUMMARY")
      ]),
      _c("div", { staticClass: "flex justify-center mt-12" }, [
        _c("div", { staticClass: "w-12 border-b-2 border-white" })
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col justify-around mt-48 sm:flex-row" },
        [
          _c("div", { staticClass: "px-6" }, [
            _c(
              "div",
              { staticClass: "p-2 text-center bg-black rounded bg-opacity-30" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-xl font-semibold text-blue-300 text-shadow whitespace-nowrap"
                  },
                  [_vm._v(" ORCHESTRATE GLOBALLY ")]
                ),
                _c("div", { staticClass: "text-white" }, [
                  _vm._v("Rapid Content Updates")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "px-6 my-48" }, [
            _c(
              "div",
              { staticClass: "p-2 text-center bg-black rounded bg-opacity-30" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-xl font-semibold text-red-500 text-shadow whitespace-nowrap"
                  },
                  [_vm._v(" SCALE DELIVERY ")]
                ),
                _c("div", { staticClass: "text-white" }, [
                  _vm._v("Secure Cloud and Edge")
                ])
              ]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-center" }, [
      _c("div", { staticClass: "w-12 border-b-2 border-white" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-col justify-between text-white lg:flex-row section-content"
      },
      [
        _c("div", { staticClass: "flex justify-center" }, [
          _c("div", [
            _c("div", { staticClass: "text-2xl text-center" }, [
              _vm._v("END USERS")
            ]),
            _c("div", { staticClass: "flex justify-center mt-12" }, [
              _c("div", { staticClass: "w-12 border-b-2 border-white" })
            ]),
            _c("div", { staticClass: "my-12 text-center" }, [
              _vm._v(" Built "),
              _c("span", { staticClass: "text-green-400" }, [
                _vm._v("With And For")
              ]),
              _vm._v(" The Operator ")
            ]),
            _c("div", { staticClass: "text-center" }, [
              _vm._v(" Designed From The Ground Up To Support: "),
              _c(
                "ul",
                {
                  staticClass:
                    "my-12 text-xl list-disc list-inside lg:text-left"
                },
                [
                  _c("li", [_vm._v("Tactical Air Control Parties (TACPs)")]),
                  _c("li", { staticClass: "my-12" }, [
                    _vm._v("Air Operations Centers (AOCs)")
                  ]),
                  _c("li", [_vm._v("Advanced Battle Management System (ABMS)")])
                ]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "relative flex justify-center pl-24" }, [
          _c("img", {
            staticClass: "absolute self-center -ml-96 z-5",
            attrs: {
              src: require("@/assets/heropage/p1-logo.png"),
              alt: "Platform 1 Logo"
            }
          }),
          _c("img", {
            staticClass: "self-center z-1",
            attrs: {
              height: "680",
              width: "536",
              src: require("@/assets/heropage/skyhub-mobile-app.png"),
              alt: "Skyhub app"
            }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-content" }, [
      _c("div", { staticClass: "text-2xl text-center text-white" }, [
        _vm._v("CONNECT")
      ]),
      _c("div", { staticClass: "flex justify-center mt-12" }, [
        _c("div", { staticClass: "w-12 border-b-2 border-white" })
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col justify-center mt-12 lg:flex-row" },
        [
          _c("iframe", {
            staticClass: "w-full border-0 lg:w-1/2",
            attrs: {
              src:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.610468885555!2d-122.20319588426585!3d47.61426349544172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906c87b4e2f915%3A0x7d3508c77858df2b!2s10400%20NE%204th%20St%2C%20Bellevue%2C%20WA%2098004!5e0!3m2!1sen!2sus!4v1615927371270!5m2!1sen!2sus",
              height: "500",
              allowfullscreen: "",
              loading: "lazy"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }