var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c("div", { staticClass: "flex flex-col lg:flex-row" }, [
        _c("div", { staticClass: "w-full mb-6 lg:w-1/2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.firstName,
                expression: "model.firstName"
              }
            ],
            staticClass:
              "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
            attrs: { id: "firstName", type: "text", placeholder: "First Name" },
            domProps: { value: _vm.model.firstName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "firstName", $event.target.value)
              }
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "w-full mx-0 my-4 mb-6 lg:w-1/2 lg:ml-8 lg:my-0" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.lastName,
                  expression: "model.lastName"
                }
              ],
              staticClass:
                "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
              attrs: { id: "lastName", type: "text", placeholder: "Last Name" },
              domProps: { value: _vm.model.lastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "lastName", $event.target.value)
                }
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "flex flex-col lg:flex-row" }, [
        _c("div", { staticClass: "w-full mb-6 lg:w-1/2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.email,
                expression: "model.email"
              }
            ],
            staticClass:
              "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
            attrs: { id: "email", type: "text", placeholder: "Email" },
            domProps: { value: _vm.model.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "email", $event.target.value)
              }
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "w-full mx-0 my-4 lg:w-1/2 lg:ml-8 lg:my-0" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.phone,
                  expression: "model.phone"
                }
              ],
              staticClass:
                "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
              attrs: { id: "phone", type: "text", placeholder: "Phone" },
              domProps: { value: _vm.model.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "phone", $event.target.value)
                }
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "flex flex-col mb-6 lg:flex-row" }, [
        _c("div", { staticClass: "w-full mx-0 my-4 lg:my-0" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.message,
                expression: "model.message"
              }
            ],
            staticClass:
              "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
            attrs: { id: "message", placeholder: "Type your message here..." },
            domProps: { value: _vm.model.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "message", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c(
        "button",
        {
          staticClass: "p-8 py-2 mb-6 text-white bg-blue-300 rounded",
          attrs: { disabled: !_vm.isValid },
          on: { click: _vm.submit }
        },
        [_vm._v(" Submit ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }