



















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ContactFormComponent, {
  ContactFormModel
} from '@/components/ContactFormComponent.vue';

const HeroPageSections = {
  HOME: 'Home',
  PRODUCT_SUMMARY: 'Product Summary',
  VISION: 'Vision',
  END_USER: 'End Users',
  CONNECT: 'Connect'
};

@Component<HeroPageComponent>({
  components: {
    ContactForm: ContactFormComponent
  }
})
export default class HeroPageComponent extends Vue {
  private sections = HeroPageSections;
  private menuItems = Object.values(HeroPageSections);
  private contactForm: ContactFormModel | null = null;

  private linkedInUrl = 'https://www.linkedin.com/company/intrinsic-ventures';
  private facebookUrl = 'https://www.facebook.com/IntrinsicVentures/';
  private youtubeVideoUrl =
    'https://www.youtube.com/embed/U_viAJNX_mo?rel=0&loop=1&playlist=U_viAJNX_mo&controls=0&showinfo=0&autohide=1&modestbranding=1';
  private companyEmail = 'opportunity@intrinsic.ventures';
  private companyAddress =
    '6947 Coal Creek Pkwy SE STE 186 Newcastle, WA 98059';

  private scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // private formSubmitHandler(form: ContactFormModel) {
  //   this.contactForm = form;
  // }
}
